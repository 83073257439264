import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImg from "../../../images/CounterCulture_Big.jpg"
import Romans from "../../../images/practiceTitle.jpeg"
import Rise from "../../../images/Easter2022.jpg"
import Friends from "../../../images/friendsDayEvent.jpeg"
import Partnerships from "../../../images/PartnershipsTitle.jpg"
import CounterCulture from "../../../images/counterCulture_title.jpeg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Counter Culture"
    SecondText="Check out sermons from our Counter Culture sermon series"
  >
    <SEO title="Sermons - Counter Culture" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
              <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="Taking Aim"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/taking-aim"
        date="August 28, 2022"
        pastor="Pastor Andrew"
      />
          <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="The Bigger Picture"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/the-bigger-picture"
        date="August 21, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="Failure and Faithfulness"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/failure-and-faithfulness"
        date="August 14, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="We Have a Promise"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/we-have-a-promise"
        date="August 7, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="We Have a Protector"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/we-have-a-protector"
        date="July 31, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="We Have a Problem"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/we-have-a-problem"
        date="July 24, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="Leaving a Legacy"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/leaving-a-legacy"
        date="July 17, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="A Failed Offering"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/a-failed-offering"
        date="July 10, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="Seeing God's Best"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/seeing-gods-best"
        date="July 3, 2022"
        pastor="Scott Trefny"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="Falling to Temptation"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/falling-to-temptation"
        date="June 26, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="The Happiest Place on Earth"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/happiest-place-on-earth"
        date="June 19, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="Imago Dei"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/imago-dei"
        date="June 12, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="The End of Forming and Filling"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/forming-and-filling"
        date="June 5, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="Water and Life"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/water-and-life"
        date="May 29, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="Light to Lights"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/light-to-lights"
        date="May 22, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={CounterCulture}
        imageAlt="Counter Culture"
        sermonTitle="The Beginning"
        sermonSeries="Counter Culture"
        sermonLink="/phoenix/sermons/the-beginning"
        date="May 15, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/phoenix/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Phoenix"
      />
    </Grid>
  </Layout>
)

export default IndexPage
